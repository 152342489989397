<template>
  <Field
    :id="id"
    :label="label"
    :icon="icon"
    :dark="dark"
    :error="error"
    :required="required"
  >
    <input
      v-model="value"
      v-bind="$attrs"
      :aria-label="label"
      :placeholder="label"
      :type="type ?? 'text'"
      class="peer w-full border-b px-3 pb-2 pt-4 text-sm transition-colors placeholder:text-transparent read-only:cursor-not-allowed read-only:bg-gray-10 focus:outline-none active:outline-none"
      :class="{
        'border-gray-300 bg-transparent text-white': dark,
        'border-gray-200 text-black hover:border-gray-700 hover:bg-transparent focus:border-gray-700':
          !dark,
        'border-red-600 bg-red-50': !!error,
        'pr-8': !!icon,
      }"
    />
  </Field>
</template>

<script lang="ts" setup>
const props = withDefaults(
  defineProps<{
    modelValue?: string | number
    label: string
    pattern?: RegExp
    icon?: string
    error?: string | boolean
    type?: string
    dark?: boolean
    required?: boolean
  }>(),
  {
    iconClasses: 'w-5 mx-2',
    dark: false,
    required: false,
  }
)
const emit = defineEmits(['update:modelValue'])

const id = computed(
  () => props.label?.toLowerCase().replace(/\s/g, '-') + '-input'
)
const value = useVModel(props, 'modelValue', emit)
</script>
